import Image from 'next/image';
import React from 'react';
import StarRatings from 'react-star-ratings';

import reviewsIo from '../../../static/images/logos/reviews-io.png';
import reviewsIoSquare from '../../../static/images/logos/reviewsio-logo--stacked@2x.png';
import { markdownify } from '../../../utils';

function Reviews({ type, reviews }) {
	const word = reviews && reviews.word ? reviews.word : 'Excellent';

	const average_rating = reviews && reviews.stats ? reviews.stats.average_rating : '4.3';
	const total_reviews = reviews && reviews.stats ? reviews.stats.total_reviews : '3500+';

	const reviews_list = reviews && reviews.reviews ? reviews.reviews : static_reviews;

	return (
		<>
			{type === 'vertical' && (
				<div className="reviews sidebar">
					<div className="head">
						<h5>We Come to Your Location and Provide Our Expertise with Stellar Results</h5>
					</div>
					<div className="intro">
						<div className="items">
							<div className="item condition">
								<span>EXCELLENT</span>
							</div>

							<div className="item review-stars">
								<StarRatings
									numberOfStars={5}
									rating={4.5}
									starDimension="16px"
									starSpacing="0"
									starRatedColor="#FF9966"
									starEmptyColor="grey"
								/>
							</div>
						</div>

						<div className="items">
							<div className="item">
								<span>
									<b>{average_rating}</b> Average
								</span>
							</div>

							<div className="item">
								<span>
									<b>{total_reviews}</b> Reviews
								</span>
							</div>
						</div>	
					</div>

					<div className="body">
						{reviews_list.map((data, index) => {
							return (
								<blockquote className="blockquote" key={index}>
									<p className="mb-0">{markdownify(data.comments)}</p>
									<div className="blockquote-footer">
										<span className="mr-1">
											{data.reviewer.first_name} {data.reviewer.last_name}
										</span>
										<StarRatings
											numberOfStars={5}
											rating={data.rating}
											starDimension="14px"
											starSpacing="0"
											starRatedColor="#FF9966"
											starEmptyColor="grey"
										/>
									</div>
									<small className="mt-1">{data.date}</small>
								</blockquote>
							);
						})}
					</div>

					<footer>
						<p>
							Read all on
							<a
								target="_blank"
								href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
								rel="noreferrer">
								<img src={reviewsIo.src} alt="reviews_logo" />
							</a>
						</p>
					</footer>
				</div>
			)}

			{type === 'carousel' && (
				<div className="reviews carousel">
					<div id="carousel-widget"></div>
				</div>
			)}

			{type === 'dropdown' && (
				<a
					className="reviews dropdown"
					href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
					target="_blank"
					rel="noreferrer">
					<StarRatings
						numberOfStars={5}
						rating={4.5}
						starDimension="17px"
						starSpacing="0"
						starRatedColor="#FF9966"
						starEmptyColor="grey"
					/>
					<ul>
						<li>
							<span>{average_rating}</span> Avg.
						</li>
						<li>
							<span>{total_reviews}</span> Reviews
						</li>
					</ul>
				</a>
			)}

			{type === 'textBanner' && (
				<div className="reviews text-banner">
					<div className="intro">
						<div className="logo">
							<Image width={50} height={50} src={reviewsIoSquare} alt="reviews_logo" />
						</div>

						<div className="text">
							<span className="p">
								Our customers say <span>Excellent </span>
								<StarRatings
									numberOfStars={5}
									rating={4.5}
									starDimension="16px"
									starSpacing="0"
									starRatedColor="#FF9966"
									starEmptyColor="grey"
								/>
								<span> ({average_rating})</span> out of 5 based on <span>{total_reviews}</span> reviews
							</span>
						</div>
					</div>
				</div>
			)}

			{type === 'minimal' && (
				<div className="reviews minimal">
					<span className="p">
						Our customers say <b>Excellent </b>
						<StarRatings
							numberOfStars={5}
							rating={4.5}
							starDimension="16px"
							starSpacing="0"
							starRatedColor="#FF9966"
							starEmptyColor="grey"
						/>
						<span>
							{' '}
							(<b>{average_rating}</b>)
						</span>{' '}
						out of <b>5</b> based on <b>{total_reviews}</b> reviews
					</span>
				</div>
			)}

			{type === 'static' && (
				<div className="reviews static">
					<h3>We Come to Your Location and Provide Our Expertise with Stellar Results</h3>
					<div className="intro">
						<span className="p">
							Our customers say <span>Excellent </span>
							<StarRatings
								numberOfStars={5}
								rating={4.5}
								starDimension="23px"
								starSpacing="0"
								starRatedColor="#FF9966"
								starEmptyColor="grey"
							/>
							<span> ({average_rating})</span> out of 5 based on <span>{total_reviews}</span> reviews
						</span>
					</div>

					<div className="review-items">
						<div className="review-item">
							<blockquote>
								Really phenomenal from start to finish. Customer service was great in the beginning
								helping with questions and scheduling. Our installer was a great guy and quick.
							</blockquote>
							<small>Tom</small>
						</div>

						<div className="review-item">
							<blockquote>
								From the time I called to completion was about an hour. Anywhere else, I would have
								waited at least an hour before work started. And I never had to leave home!
							</blockquote>
							<small>William S</small>
						</div>

						<div className="review-item">
							<blockquote>
								{`Incredibly convenient, courteous, and competent. Hoping I won't have a car battery die unexpectedly on me again...but if it does happen, will definitely use this service.`}
							</blockquote>
							<small>Ben B</small>
						</div>
					</div>
					<div className="view-more">
						<p>
							Read all on
							<a
								className="position-relative"
								target="_blank"
								href="https://www.reviews.io/company-reviews/store/1800battery.com-0900"
								rel="noreferrer">
								<Image width="128" height="20" objectFit="contain" src={reviewsIo} alt="reviews_logo" />
							</a>
						</p>
					</div>
				</div>
			)}
		</>
	);
}

export default Reviews;
const static_reviews = [
	{
		branch: null,
		comments:
			'I talked to Chris about my sons car battery and he had someone out to my\r\nhome within the hour! More expensive \r\nthan other quotes I got but, for me the convince of have a Tech coming out to\r\nmy house was well worth it. I forgot the Tech&rsquo;s name but he was very nice and quickly changed the battery.',
		date: '7 hours ago',
		date_created: '2021-07-07 03:26:19',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Lana', last_name: 'R', verified_buyer: 'yes', address: 'Fort Collins, United States' },
		store_branch_id: null,
		store_review_id: 12470408,
		title: '',
		user_id: 17300814,
	},

	{
		branch: null,
		comments:
			'Guy was friendly and quick. He replaced my old battery and my car started right up. Took about 15-20 min.',
		date: '14 hours ago',
		date_created: '2021-07-06 20:51:11',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Chris', last_name: 'M', verified_buyer: 'yes', address: 'Bentonville, United States' },
		store_branch_id: null,
		store_review_id: 12469143,
		title: '',
		user_id: 17299080,
	},

	{
		branch: null,
		comments:
			'My technician, Rossalyn, was prompt, professional, friendly, and highly skilled. She had both raw technical skill and empathy, and really made me see the true value in this service. I would 100% recommend her and 1-800-Battery to anyone in need as an alternative to towing your car or even any similar service. Five stars, two thumbs way up!',
		date: '2 days ago',
		date_created: '2021-07-05 03:31:38',
		images: [],
		rating: 5,
		ratings: [],
		reviewer: { first_name: 'Kevin', last_name: 'S', verified_buyer: 'yes', address: '' },
		store_branch_id: 0,
		store_review_id: 12452075,
		title: '',
		user_id: 17274147,
	},
];
